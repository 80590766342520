<template>
  <v-container class="find-similar mt-3 px-sm-10 px-3" fluid>
    <div
      class="nav-link d-flex mb-6 mt-10"
      @click="
        $router.push({
          name: 'ContactDetails',
          params: { id: contactDetails.id },
        })"
    >
      <div class="pr-2">
        &#60;
      </div>
      <div>Return to Digital Persona</div>
    </div>
    <PageHeader
      v-if="contactDetails"
      header-text="Find Similar"
    >
      <template #subheader>
        Find contacts similar to
        <span class="name-blue">
          {{ `${contactDetails.firstName} ${contactDetails.lastName}` }}
        </span>
      </template>
    </PageHeader>

    <v-row class="mt-10">
      <v-col cols="12" sm="6">
        <div class="field__header mb-5">
          Contact Fields
        </div>
        <div class="ml-4">
          <v-checkbox
            v-for="(item, index) in statisticSearchFields"
            :key="item.field"
            v-model="selectedStatistics[index]"
            @change="estimate"
          >
            <div slot="label" class="ml-2">
              <!--Here we hardcode path to statistic query schema for gettin label to display-->
              <span class="point-text">{{ querySchema.contact_statistics.fields[item.field].label }}:</span>&nbsp;
              <span v-if="statisticIsRate(querySchema.contact_statistics.fields[item.field].label)" class="percentage">{{ Number.isInteger(item.value) ? item.value : item.value.toFixed(1) }}%</span>
              <span v-else class="percentage">
                {{ item.value.name ? item.value.name : item.value }}
              </span>
            </div>
          </v-checkbox>
        </div>
        <div class="ml-4">
          <v-checkbox
            v-for="(item, index) in contactSearchFields"
            :key="item.field"
            v-model="selectedContact[index]"
            @change="estimate"
          >
            <div slot="label" class="ml-2">
              <!--Here we hardcode path to statistic query schema for gettin label to display-->
              <span class="point-text">{{ querySchema.contact.fields[item.field].label }}:</span>&nbsp;
              <span class="percentage">{{ item.name }}</span>
            </div>
          </v-checkbox>
        </div>
      </v-col>

      <v-col
        v-if="customSearchFields.length"
        cols="12"
        sm="6"
      >
        <div class="field__header mb-5">
          Custom Fields
        </div>
        <div class="ml-4">
          <v-checkbox
            v-for="(item, index) in customSearchFields"
            :key="item.field"
            v-model="selectedCustom[index]"
            @change="estimate"
          >
            <div slot="label" class="ml-2">
              <!--Here we hardcode path to statistic query schema for gettin label to display-->
              <span class="point-text">{{ querySchema.contact.fields[item.field].label }}:</span>&nbsp;
              <span class="percentage">{{ item.value }}</span>
            </div>
          </v-checkbox>
        </div>
      </v-col>
    </v-row>

    <v-divider class="mb-8" />

    <div
      v-if="locationSearchFields.length"
      class="search-table"
    >
      <v-simple-table>
        <thead>
          <tr class="search-table__header">
            <!--<th>Item ID</th>-->
            <th>Location</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
          </tr>
        </thead>
        <tbody class="search-table__content">
          <tr v-for="(item, index) in locationSearchFields" :key="index">
            <!--<td> {{ item.id }} </td>-->
            <td style="color: #000">
              {{ item[1].value }} {{ item[2].value }}
            </td>
            <td>
              <v-checkbox
                v-if="item[3].value"
                v-model="selectedLocations[index][3]"
                @change="estimate"
              >
                <div slot="label">
                  {{ item[3].value }}
                </div>
              </v-checkbox>
            </td>
            <td>
              <v-checkbox
                v-if="item[4].value"
                v-model="selectedLocations[index][4]"
                @change="estimate"
              >
                <div slot="label">
                  {{ item[4].value }}
                </div>
              </v-checkbox>
            </td>
            <td>
              <v-checkbox
                v-if="item[5].value"
                v-model="selectedLocations[index][5]"
                @change="estimate"
              >
                <div slot="label">
                  {{ item[5].value }}
                </div>
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-row class="mt-12">
      <v-col cols="12" sm="8" class="d-flex align-center">
        <div class="large-regular-text mr-1">
          Estimated number of contacts:
        </div>
        <span class="percentage font-15px">{{ estimatedContacts }}</span>
      </v-col>
      <v-col cols="12" sm="4" class="d-flex justify-end">
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          width="150px"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="disableSearch"
          @click="runSearch"
        >
          Find Similar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "FindSimilar",
  metaInfo: {
    title: 'Find Similar'
  },
  components: {
    PageHeader,
  },
  props: {
    id: {
      default: () => false
    }
  },
  data() {
    return {
      selectedStatistics: [],
      selectedLocations: [],
      selectedCustom: [],
      selectedContact: [],
      estimatedContacts: 0,
      criteria: {
        operator: "AND",
        groups: [
          {
            operator: "AND",
            conditions: [
            ],
          },
        ],
      },
      contactFields: {},
      customFields: {},
      contactsTable: [],
      contactsTotal: '0' ,
      querySchema:{},
      statisticSearchFields: [],
      customSearchFields: [],
      locationSearchFields: [],
      contactSearchFields: [],
      contactDetails: null,
      criteriaCaches: {},
      disableSearch: false,
    };
  },
  async created() {
    await this.getContactDetails()
    await this.getQuerySchema()
    this.prepareFields()
  },
  methods: {
    statisticIsRate(val){
      return val.indexOf('Rate') > -1;
    },
    async getQuerySchema(){
      this.querySchema = (await this.$rest.contact.get_search_fields()).data;
    },
    async getContactDetails() {
      const response = await this.$rest.contact.get_contact(this.id, {groups: ['contact_location', 'source']});
      if (!response.data) {
        return;
      }

      const statisticData = await this.$rest.contact.get_contact_statistics(this.id);

      this.contactDetails = { ...response.data };

      this.contactDetails.contactStatistic = { ...statisticData.data?.contactStatistics };
    },
    prepareFields(){
      let contactFilters = this.querySchema.contact.fields;
      let contactStatisticsFilters = this.querySchema.contact_statistics.fields;
      let contactLocationFilters = this.querySchema.location.fields;
      Object.keys(contactFilters).filter(i => i.includes('customFields')).forEach(i => {
        if (this.contactDetails.customFields[i.split('.')[1]] != null) {
          this.customSearchFields.push({
            field: i,
            operator: this.mapOperator(contactFilters[i].type),
            value: this.contactDetails.customFields[i.split('.')[1]],
          })
        }
      })
      Object.keys(contactStatisticsFilters).forEach(i => {
        if (i !== 'openRate' && i !== 'clickRate' && (this.contactDetails.contactStatistic[i] || this.contactDetails.contactStatistic[i] === 0)) {
          this.statisticSearchFields.push({
            field: i,
            operator: this.mapOperator(contactStatisticsFilters[i].type),
            value: this.contactDetails.contactStatistic[i],
          })
        }
      })
      Object.keys(contactFilters).forEach(i => {
        if (i === 'specialty' && this.contactDetails[i]) {
          this.contactSearchFields.push({
            field: i,
            operator: this.mapOperator(contactFilters[i].type),
            value: this.contactDetails[i],
            name: this.contactDetails[i],
          })
        }
        if (i === 'source' && this.contactDetails[i]) {
          this.contactSearchFields.push({
            field: i,
            operator: this.mapOperator(contactFilters[i].type),
            value: this.contactDetails[i].id,
            name: this.contactDetails[i].name,
          })
        }
      })
      this.contactDetails.contactLocations.forEach(location => {
        let temp = [];
        Object.keys(contactLocationFilters).forEach(i => {
          temp.push({
            field: i,
            operator: 'is' + (i !== 'state' ? ' exactly' : ''),
            value: location[i],
          })
        })
        this.locationSearchFields.push(temp)
      })
      for (let i = 0; i < this.locationSearchFields.length; i++){
        this.selectedLocations.push(new Array(6))
      }
    },
    runSearch(){
      this.compileCriteria();
      this.filterContacts();
    },
    compileCriteria() {
      this.criteria.groups[0].conditions = [];
      this.selectedStatistics.forEach((i, index) => {
        if (i !== false) {
          const statistic_field = cloneDeep(this.statisticSearchFields[index]);
          if(statistic_field.field === 'contentPreferenceTag' || statistic_field.field === 'leadScoreSegment') {
            statistic_field.value = statistic_field.value.id;
          }

          this.criteria.groups[0].conditions.push({...statistic_field, field: `contact_statistics.${statistic_field.field}`})
        }
      })
      this.selectedCustom.forEach((i, index) => {
        if (i !== false) {
          this.criteria.groups[0].conditions.push({...this.customSearchFields[index], field: `contact.${this.customSearchFields[index].field}`})
        }
      })
      this.selectedContact.forEach((i, index) => {
        if (i !== false) {
          this.criteria.groups[0].conditions.push({...this.contactSearchFields[index], field: `contact.${this.contactSearchFields[index].field}`})
        }
      })
      this.selectedLocations.forEach((location_container, parent_index) => {
        location_container.forEach((loc, index) => {
          if (loc !== false) {
            this.criteria.groups[0].conditions.push({...this.locationSearchFields[parent_index][index], field: `location.${this.locationSearchFields[parent_index][index].field}`})
          }
        })
      })
    },
    async filterContacts(shouldRedirect = true) {
      const params = {
        excludeTestContacts: true,
      };
      const resp = (
        await this.$rest.contact.search_collection(params, {criteria: {...this.criteria}})
      ).data;
      if (resp.searchId) {
        try {
          if (shouldRedirect) {
            await this.$router.push({
              name: "ContactsSearch",
              params: { searchId: resp.searchId },
            });
          }
        } catch (e) {
          // HACK: some hack to avoid error from router
          console.error(e);
        }
      }
      this.contactsTotal = this.estimatedContacts = resp.totalCount;
    },
    async estimate() {
      this.compileCriteria();

      // if criteria is none, we shouldn't request for anything, just simply put the estimated back to 0
      if (this.criteria.groups[0].conditions.length <= 0) {
        this.contactsTotal = this.estimatedContacts = 0;
        return;
      }

      // check cache
      const criteriaJsonStr = JSON.stringify(this.criteria);
      if (this.criteriaCaches[criteriaJsonStr]) {
        // already in cache, get the value there
        this.contactsTotal = this.estimatedContacts = this.criteriaCaches[criteriaJsonStr];
        return;
      }

      await this.filterContacts(false);
      this.criteriaCaches[criteriaJsonStr] = this.estimatedContacts; // added the cache
    },
    mapOperator(fieldType) {
      switch(fieldType) {
        case 'number':
          return 'is similar to';
        case 'yes_or_no':
        case 'database':
        case 'date':
          return 'is';
        case 'text':
          return 'contains';
        default:
          return 'is equal to';
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #2b84eb;
  cursor: pointer;
}
.name-blue {
  color: #2b84eb;
  font-weight: 600;
}
.point-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}
.percentage {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b84eb;
}
.large-regular-text {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #241c15;
}
.font-15px {
  font-size: 15px !important;
}

.find-similar {
  .v-input.v-input--checkbox {
    .v-icon {
      font-size: 18px;
    }
  }
  .search-table {
    ::v-deep .v-messages {
      display: none;
    }
    ::v-deep .v-input__slot {
      margin: 0;
    }
  }
  ::v-deep .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
}
.search-table__content {
  ::v-deep .v-label div {
    font-size: 0.875rem;
    color: #000;
  }
}
</style>
